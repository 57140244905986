import { Elm } from './Main.elm'
import * as MarkdownEditor from './MarkdownEditor.js'
import * as Map from './Map.js'

import illusionsHotelListJson from './illusions-hotel-list.json'

MarkdownEditor.defineWebComponent()
Map.defineWebComponent()

const nucleoUrl = document.querySelector('meta[name="nucleo-url"]').content
const showFlightPriceFeature = document.querySelector('meta[name="show-flight-price-feature"]').content
const showExternalHotelPriceFeature = document.querySelector('meta[name="show-external-hotel-price-feature"]').content
const showExpertFeature = document.querySelector('meta[name="show-expert-feature"]').content
const showOptionsFeature = document.querySelector('meta[name="show-options-feature"]').content

console.log('nucleo-url: ', nucleoUrl)
console.log('show-flight-price-feature: ', showFlightPriceFeature)
console.log('show-external-hotel-price-feature', showExternalHotelPriceFeature)
console.log('show-expert-feature', showExpertFeature)
console.log('show-options-feature', showOptionsFeature)

const flags = {
  nucleoToken: window.localStorage.token || null,
  nucleoUrl: nucleoUrl,
  showFlightPriceFeature: showFlightPriceFeature === 'true',
  showExternalHotelPriceFeature: showExternalHotelPriceFeature === 'true',
  showExpertFeature: showExpertFeature === 'true',
  showOptionsFeature: showOptionsFeature === 'true',
  illusionsHotelListJson: illusionsHotelListJson
}

const app = Elm.Main.init({ flags: flags })

app.ports.consoleLog.subscribe(function (data = {}) {
  const severity = data.severity

  switch (severity) {
    case 'info':
      console.info(data.prefix, data.value)
      break
    case 'warning':
      console.warn(data.prefix, data.value)
      break
    case 'error':
      console.error(data.prefix, data.value)
      break
    default:
      console.log(data.prefix, data.value)
      break
  }
})

app.ports.storeToken.subscribe(function (token) {
  window.localStorage.token = token
})

app.ports.deleteToken.subscribe(function () {
  window.localStorage.removeItem('token')
})

app.ports.toggleBodyClass.subscribe(function ([className, shouldAdd]) {
  const bodyClassList = document.body.classList

  if (shouldAdd) {
    bodyClassList.add(className)
  } else {
    bodyClassList.remove(className)
  }
})

// This fixes Firefox Html5 drag and drop behaviour.
// see <https://package.elm-lang.org/packages/norpan/elm-html5-drag-drop/latest/Html5-DragDrop#getDragstartEvent>
app.ports.dragstart.subscribe(function (event) {
  event.dataTransfer.setData('text', '')
})

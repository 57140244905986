import markdownit from 'markdown-it'
import { Schema } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'
import { EditorState } from 'prosemirror-state'
import { defaultMarkdownParser, defaultMarkdownSerializer, MarkdownParser, schema } from 'prosemirror-markdown'
import { exampleSetup } from 'prosemirror-example-setup'
import { blockTypeItem, MenuItem } from 'prosemirror-menu'
import { joinUp, lift, toggleMark } from 'prosemirror-commands'
import { redo, undo } from 'prosemirror-history'
import { wrapInList } from 'prosemirror-schema-list'
import '@webcomponents/custom-elements/src/native-shim'

// Clone default markdown schema and make lists tight by default.
// See <https://discuss.prosemirror.net/t/lists-default-tight/1483/14>
const markdownSchema = new Schema({
  nodes: schema.spec.nodes
    .update('ordered_list', Object.assign({}, schema.spec.nodes.get('ordered_list'),
      { attrs: { order: { default: 1 }, tight: { default: true } } }))
    .update('bullet_list', Object.assign({}, schema.spec.nodes.get('bullet_list'),
      { attrs: { tight: { default: true } } })),
  marks: schema.spec.marks
})

// This a copy of the default markdownParser implementation, except that is uses
// our custom markdown schema.
const markdownParser =
  new MarkdownParser(markdownSchema, markdownit('commonmark', { html: false }), defaultMarkdownParser.tokens)

const fasIcons = {
  strong: fasIcon('bold'),
  em: fasIcon('italic'),
  undo: fasIcon('undo'),
  redo: fasIcon('redo'),
  bulletList: fasIcon('list-ul'),
  orderedList: fasIcon('list-ol'),
  paragraph: fasIcon('paragraph'),
  heading: fasIcon('heading'),
  lift: fasIcon('outdent'),
  join: fasIcon('angle-double-up'),
  link: fasIcon('link')
}

const plugins =
  exampleSetup({
    schema: markdownSchema,
    menuContent: buildMenuContent(markdownSchema),
    floatingMenu: false // The menubar is made sticky via CSS
  })

class ProseMirrorView {
  constructor (target, content, onInput, onBlur, onFocus) {
    let view = new EditorView(target, {
      state: EditorState.create({
        doc: markdownParser.parse(content),
        plugins: plugins
      }),
      dispatchTransaction (transaction) {
        let oldMarkdown = defaultMarkdownSerializer.serialize(view.state.doc)
        view.updateState(view.state.apply(transaction))
        let newMarkdown = defaultMarkdownSerializer.serialize(view.state.doc)
        // Only call onInput when the markdown changed. This fixes an issue when
        // only moving the pointer would mark the form field as dirty on the Elm side.
        if (oldMarkdown !== newMarkdown) {
          onInput(newMarkdown)
        }
      },
      handleDOMEvents: {
        focus: () => {
          onFocus()
        },
        blur: () => {
          onBlur()
        }
      }
    })
    this.view = view
  }

  get content () {
    return defaultMarkdownSerializer.serialize(this.view.state.doc)
  }

  focus () {
    this.view.focus()
  }

  destroy () {
    this.view.destroy()
  }

  setValue (value) {
    this.view.updateState(EditorState.create({
      doc: markdownParser.parse(value),
      plugins: plugins
    }))
  }
}

/* globals HTMLElement, CustomEvent */
class MarkdownEditorWebComponent extends HTMLElement {
  constructor () {
    super()
    this._editorValue = ''
    this.onInputCallback = this.onInputCallback.bind(this)
    this.onBlurCallback = this.onBlurCallback.bind(this)
    this.onFocusCallback = this.onFocusCallback.bind(this)
  }

  get editorValue () {
    return this._editorValue
  }

  set editorValue (value) {
    if (this._editorValue === value) {
      return
    }

    this._editorValue = value

    if (!this._editor) {
      return
    }

    this._editor.setValue(value)
  }

  onInputCallback (content) {
    this._editorValue = content
    this.dispatchEvent(new CustomEvent('editorChanged'))
  }

  onBlurCallback () {
    this.dispatchEvent(new CustomEvent('editorBlur'))
  }

  onFocusCallback () {
    this.dispatchEvent(new CustomEvent('editorFocus'))
  }

  connectedCallback () {
    this._editor = new ProseMirrorView(this, this._editorValue, this.onInputCallback, this.onBlurCallback, this.onFocusCallback)
  }
}

export function defineWebComponent () {
  window.customElements.define('markdown-editor', MarkdownEditorWebComponent)
}

function fasIcon (name) {
  let node = document.createElement('i')
  node.className = `fas fa-${name}`
  return { dom: node }
}

// Most of the following functions are taken from
// https://github.com/ProseMirror/prosemirror-menu/blob/master/src/menu.js
// and adapted to our needs.

function cmdItem (cmd, options) {
  let passedOptions = {
    label: options.title,
    run: cmd
  }
  for (let prop in options) {
    passedOptions[prop] = options[prop]
  }
  if ((!options.enable || options.enable === true) && !options.select) {
    passedOptions[options.enable ? 'enable' : 'select'] = state => cmd(state)
  }

  return new MenuItem(passedOptions)
}

function markActive (state, type) {
  let { from, $from, to, empty } = state.selection
  if (empty) return type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

function markItem (markType, options) {
  let passedOptions = {
    active (state) {
      return markActive(state, markType)
    },
    enable: true
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  return cmdItem(toggleMark(markType), passedOptions)
}

function linkItem (markType) {
  return new MenuItem({
    title: 'Add or remove link',
    icon: fasIcons.link,
    active (state) {
      return markActive(state, markType)
    },
    enable (state) {
      return !state.selection.empty
    },
    run (state, dispatch, view) {
      if (markActive(state, markType)) {
        toggleMark(markType)(state, dispatch)
        return true
      }

      let href = promptForURL()
      if (!href) return false

      toggleMark(markType, { href })(view.state, view.dispatch)
      view.focus()
    }
  })
}

function promptForURL () {
  let url = window.prompt('Enter the URL', 'https://')
  if (url && !/^https?:\/\//i.test(url)) {
    url = 'http://' + url
  }
  return url
}

function wrapListItem (nodeType, options) {
  return cmdItem(wrapInList(nodeType, options.attrs), options)
}

function buildMenuContent (schema) {
  const inlineMenu = [
    markItem(schema.marks.strong, { title: 'Toggle strong style', icon: fasIcons.strong }),
    markItem(schema.marks.em, { title: 'Toggle emphasis', icon: fasIcons.em }),
    linkItem(schema.marks.link)
  ]

  const typeMenu = [
    blockTypeItem(schema.nodes.paragraph, {
      title: 'Change to paragraph',
      icon: fasIcons.paragraph
    }),
    blockTypeItem(schema.nodes.heading, {
      title: 'Change to heading ',
      icon: fasIcons.heading,
      attrs: { level: 3 }
    })

  ]

  const blockMenu = [
    wrapListItem(schema.nodes.bullet_list, {
      title: 'Wrap in bullet list',
      icon: fasIcons.bulletList
    }),
    wrapListItem(schema.nodes.ordered_list, {
      title: 'Wrap in ordered list',
      icon: fasIcons.orderedList
    }),
    new MenuItem({
      title: 'Join with above block',
      run: joinUp,
      select: state => joinUp(state),
      icon: fasIcons.join
    }),
    new MenuItem({
      title: 'Lift out of enclosing block',
      run: lift,
      select: state => lift(state),
      icon: fasIcons.lift
    })
  ]

  const historyMenu = [
    new MenuItem({
      title: 'Undo last change',
      run: undo,
      enable: state => undo(state),
      icon: fasIcons.undo
    }),
    new MenuItem({
      title: 'Redo last change',
      run: redo,
      enable: state => redo(state),
      icon: fasIcons.redo
    })
  ]

  return [
    inlineMenu,
    typeMenu,
    historyMenu,
    blockMenu
  ]
}

import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { fromLonLat, toLonLat } from 'ol/proj'
import Point from 'ol/geom/Point.js'
import Feature from 'ol/Feature.js'
import VectorSource from 'ol/source/Vector.js'
import { Fill, Style, Text } from 'ol/style.js'
import { Vector as VectorLayer } from 'ol/layer.js'
import { defaults } from 'ol/control/util.js'

class OpenStreetMap {
  constructor (target, location, onLocationChanged) {
    this.iconGeometry = null

    this.vectorSource = new VectorSource()

    const view = (location == null) ? new View({ maxZoom: 18, center: [0, 0], zoom: 2 }) : new View({ maxZoom: 18, center: fromLonLat(location), zoom: 7 })

    const map = new Map({
      target: target,
      controls: defaults({
        attributionOptions: {
          collapsible: false
        }
      }),
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        new VectorLayer({ source: this.vectorSource })
      ],
      view: view
    })

    this.map = map

    if (location !== null) {
      this.setLocation(location)
    }

    this.map.on('singleclick', function (evt) {
      onLocationChanged(toLonLat(evt.coordinate))
    })
  }

  setLocation (location) {
    if (this.iconGeometry === null) {
      this.iconGeometry = new Point(fromLonLat(location))

      const geoMarker = new Feature({
        geometry: this.iconGeometry,
        name: 'geoMarker'
      })

      const iconStyle = new Style({
        text: new Text({
          text: '\uf3c5', // Unicode of `map-marker-alt` icon
          font: '900 48px "Font Awesome 5 Free"', // The 900 weight is needed to properly render the icon
          textBaseline: 'bottom',
          fill: new Fill({
            color: 'red'
          })
        })
      })

      geoMarker.setStyle(iconStyle)

      this.vectorSource.addFeature(geoMarker)
    } else {
      this.iconGeometry.setCoordinates(fromLonLat(location))
    }
  }

  setFit (coordinates) {
    const minLonLat = fromLonLat([coordinates[0], coordinates[1]])
    const maxLonLat = fromLonLat([coordinates[2], coordinates[3]])

    this.map.getView().fit(minLonLat.concat(maxLonLat), { constrainResolution: false })
  }
}

/* globals HTMLElement, CustomEvent */
class OpenStreetMapWebComponent extends HTMLElement {
  constructor () {
    super()
    this._location = null
    this._fit = []
    this.onLocationChangedCallback = this.onLocationChangedCallback.bind(this)
  }

  get location () {
    return this._location
  }

  set location (value) {
    if (this._location === value) {
      return
    }

    this._location = value

    if (!this._mapView) {
      return
    }

    this._mapView.setLocation(value)
  }

  get fit () {
    return this._fit
  }

  set fit (coordinates) {
    const eq = this._fit.length === coordinates.length && this._fit.every(function (value, index) { return value === coordinates[index] })

    if (eq) {
      return
    }

    this._fit = coordinates

    if (!this._mapView) {
      return
    }

    this._mapView.setFit(coordinates)
  }

  onLocationChangedCallback (location) {
    this._location = location
    this.dispatchEvent(new CustomEvent('locationChanged'))
  }

  connectedCallback () {
    this._mapView = new OpenStreetMap(this, this._location, this.onLocationChangedCallback)
  }
}

export function defineWebComponent () {
  window.customElements.define('open-street-map', OpenStreetMapWebComponent)
}
